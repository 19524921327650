import request from '@/utils/request'

// 查询用户通讯录列表
export function listUserMessageBook (query) {
  return request({
    url: '/message/userMessageBook/list',
    method: 'get',
    params: query
  })
}

// 查询用户通讯录详细
export function getUserMessageBook (id) {
  return request({
    url: '/message/userMessageBook/' + id,
    method: 'get'
  })
}

// 查询用户通讯录详细
export function getUserMessageBookByUserId (userId) {
  return request({
    url: '/message/userMessageBook/getInfoByUserId/' + userId,
    method: 'get'
  })
}

// 新增用户通讯录
export function saveUserMessageBook (data) {
  return request({
    url: '/message/userMessageBook',
    method: 'post',
    data: data
  })
}

// 修改用户通讯录
export function updateUserMessageBook (data) {
  return request({
    url: '/message/userMessageBook',
    method: 'put',
    data: data
  })
}

// 删除用户通讯录
export function delUserMessageBook (id) {
  return request({
    url: '/message/userMessageBook/' + id,
    method: 'delete'
  })
}

// 导出用户通讯录
export function exportUserMessageBook (query) {
  return request({
    url: '/message/userMessageBook/export',
    method: 'get',
    params: query
  })
}
