<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID" prop="userId">
        <a-input v-model="form.userId" type="hidden" />
        {{ userName }}({{ nickName }})
      </a-form-model-item>
      <!-- <a-form-model-item label="消息应用" prop="appId">
        <a-select placeholder="请选择消息应用" v-model="form.appId">
          <a-select-option v-for="(d, index) in appArray" :key="index" :value="parseInt(d.id)">{{
            d.appName
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="用户通信地址" prop="userChannelAddress">
        <a-input v-model="form.userChannelAddress" placeholder="请输入用户通信地址" />
      </a-form-model-item> -->
      <div class="check-config">
        <div class="row">
          <span class="col header">消息应用</span>
          <span class="col header">用户通信地址</span>
          <span class="delete-btn header"></span>
        </div>
        <div class="row" v-for="(item, index) in form.userAppBookList" :key="index">
          <span class="col">
            <a-form-model-item
              :prop="`userAppBookList.${index}.appId`"
              :rules="[{ required: true, trigger: 'blur', message: '请选择消息应用' }]"
            >
              <a-select placeholder="请选择消息应用" v-model="item.appId">
                <a-select-option v-for="(d, aIndex) in appArray" :key="aIndex" :value="parseInt(d.id)">{{
                  d.appName
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </span>
          <span class="col">
            <a-form-model-item
              :prop="`userAppBookList.${index}.userChannelAddress`"
              :rules="[{ required: true, trigger: 'change', message: '请输入通信地址' }]"
            >
              <a-input :disabled="readOnly" placeholder="请输入通信地址" v-model="item.userChannelAddress" />
            </a-form-model-item>
          </span>
          <span class="delete-btn" v-if="!readOnly">
            <a-icon type="delete" class="item-delete" @click="handleDelete(index)" />
          </span>
        </div>
      </div>
      <div class="add-btn">
        <a-button :disabled="readOnly" @click="handleAddCheckTime" type="primary" class="add">添加</a-button>
      </div>
      <div class="bottom-control">
        <a-button type="primary" @click="submitForm"> 保存 </a-button>
        <a-button type="dashed" @click="cancel"> 取消 </a-button>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { saveUserMessageBook, getUserMessageBookByUserId } from '@/api/message/userMessageBook'

export default {
  name: 'CreateForm',
  props: {
    msgChannelTypeOptions: {
      type: Array,
      required: true
    },
    appArray: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      userName: '',
      nickName: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        appId: undefined,
        channelType: null,
        userChannelAddress: undefined,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        userAppBookList: []
      },
      readOnly: false,
      // 1增加,2修改
      formType: 1,
      open: false,
      userAppBookList: [],
      rules: {
        userId: [{ required: true, message: '用户ID不能为空', trigger: 'blur' }]
        // appId: [{ required: true, message: '请选择消息应用', trigger: 'change' }],
        // userChannelAddress: [{ required: true, message: '用户通信地址不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleAddCheckTime() {
      this.form.userAppBookList.push({
        appId: undefined,
        channelType: undefined,
        userChannelAddress: undefined
      })
      // for (let i = 0; i < this.userAppBookList.length; i++) {
      //   this.rules['appId' + i] = [{ required: true, message: '请选择消息应用', trigger: 'change' }]
      //   this.rules['userChannelAddress' + i] = [{ required: true, message: '用户通信地址不能为空', trigger: 'blur' }]
      // }
    },
    handleDelete(index) {
      this.$confirm({
        title: '确认要删除这条记录吗?',
        content: '',
        zIndex: 99999,
        onOk: () => {
          console.log('delete')
          this.form.userAppBookList.splice(index, 1)
        },
        onCancel() {}
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        appId: undefined,
        channelType: null,
        userChannelAddress: undefined,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        userAppBookList: []
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.handleAddCheckTime()
    },
    /** 修改按钮操作 */
    handleUpdate(row, userIds) {
      this.reset()
      this.formType = 2
      const userId = row ? row.userId : userIds
      getUserMessageBookByUserId(userId).then((response) => {
        this.form = response.data
        if (response.data) {
          this.userName = response.data.userName
          this.nickName = response.data.nickName
          // this.form.userAppBookList = response.data.userAppBookList
        }
        this.handleAddCheckTime()
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (this.userAppBookList.length === 0 || !this.userAppBookList[0].userChannelAddress) {
          //   this.$message.warn('请填写通讯相关信息', 3)
          //   return
          // }
          const userAppBookList = []
          for (const item of this.form.userAppBookList) {
            if (item.appId) {
              item.channelType = this.appArray.find((aItem) => aItem.id === item.appId).channelType
              item.userId = this.form.userId
              userAppBookList.push(item)
            }
          }
          saveUserMessageBook(userAppBookList).then((response) => {
            let msgTitle = ''
            if (this.form.userAppBookList && this.form.userAppBookList.length > 0) {
              msgTitle = '修改成功'
            } else {
              msgTitle = '新增成功'
            }
            this.$message.success(msgTitle, 3)
            this.open = false
            this.$emit('ok')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.check-config {
  .row {
    display: flex;
    .item-delete {
      font-size: 20px;
    }
    .col {
      flex: 1;
      text-align: center;
      vertical-align: middle;
      padding: 4px 4px;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
      .ant-form-item {
        margin-bottom: 2px;
      }
    }
    .header {
      height: 30px;
      line-height: 30px;
      padding: 0 2px;
    }
    .col.header::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .col:first-child {
      flex: 1;
      min-width: 280px;
    }
    .delete-btn {
      flex: 0 0 30px;
      border: 0;
      border-left: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add {
      width: 100%;
    }
    .ant-input-number {
      border-radius: 0;
      width: 100%;
      border: 0;
    }
    input {
      border-radius: 0;
      text-align: center;
    }
    .ant-time-picker {
      border: 0;
    }
    .ant-time-picker-input {
      border: 0 !important;
    }
    .connect-flag {
      border: 0;
      width: 5px;
    }
  }
  .row:last-child {
    .col {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
.add-btn {
  width: 100%;
  margin-top: 20px;
  margin-right: 30px;
  button {
    width: 100%;
  }
}
</style>
