var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "用户ID", prop: "userId" } },
            [
              _c("a-input", {
                attrs: { type: "hidden" },
                model: {
                  value: _vm.form.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userId", $$v)
                  },
                  expression: "form.userId",
                },
              }),
              _vm._v(
                " " + _vm._s(_vm.userName) + "(" + _vm._s(_vm.nickName) + ") "
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "check-config" },
            [
              _c("div", { staticClass: "row" }, [
                _c("span", { staticClass: "col header" }, [_vm._v("消息应用")]),
                _c("span", { staticClass: "col header" }, [
                  _vm._v("用户通信地址"),
                ]),
                _c("span", { staticClass: "delete-btn header" }),
              ]),
              _vm._l(_vm.form.userAppBookList, function (item, index) {
                return _c("div", { key: index, staticClass: "row" }, [
                  _c(
                    "span",
                    { staticClass: "col" },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "userAppBookList." + index + ".appId",
                            rules: [
                              {
                                required: true,
                                trigger: "blur",
                                message: "请选择消息应用",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择消息应用" },
                              model: {
                                value: item.appId,
                                callback: function ($$v) {
                                  _vm.$set(item, "appId", $$v)
                                },
                                expression: "item.appId",
                              },
                            },
                            _vm._l(_vm.appArray, function (d, aIndex) {
                              return _c(
                                "a-select-option",
                                {
                                  key: aIndex,
                                  attrs: { value: parseInt(d.id) },
                                },
                                [_vm._v(_vm._s(d.appName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "col" },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop:
                              "userAppBookList." +
                              index +
                              ".userChannelAddress",
                            rules: [
                              {
                                required: true,
                                trigger: "change",
                                message: "请输入通信地址",
                              },
                            ],
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              disabled: _vm.readOnly,
                              placeholder: "请输入通信地址",
                            },
                            model: {
                              value: item.userChannelAddress,
                              callback: function ($$v) {
                                _vm.$set(item, "userChannelAddress", $$v)
                              },
                              expression: "item.userChannelAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.readOnly
                    ? _c(
                        "span",
                        { staticClass: "delete-btn" },
                        [
                          _c("a-icon", {
                            staticClass: "item-delete",
                            attrs: { type: "delete" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(index)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "add-btn" },
            [
              _c(
                "a-button",
                {
                  staticClass: "add",
                  attrs: { disabled: _vm.readOnly, type: "primary" },
                  on: { click: _vm.handleAddCheckTime },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "a-button",
                { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }